<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <cmsHeader />
    <Toast />
    <div class="container">
      <div class="formcontainer form-horizontal">
        <h1>Trainer : Add </h1>
        <p>Create a new trainer.</p>
        
        <div class="form-group form-group-sm">
          <label class="col-xs-12 col-sm-2 control-label" for="name"> Name <span class="required">*</span> : </label>
          <div class="col-xs-12 col-sm-8">
            <InputText name="name" class="formwidthmedium form-control" v-model="name" size="30" :class="{ 'p-invalid': errors.name }" />
          </div>
        </div>
        <div class="error-message"><small id="status_help">{{errors.name}}</small></div>
        <div class="form-group form-group-sm">
          <label class="col-xs-12 col-sm-2 control-label" for="title"> Title <span class="required">*</span> : </label>
          <div class="col-xs-12 col-sm-8">
            <InputText name="Title" class="formwidthmedium form-control" v-model="title" size="60" :class="{ 'p-invalid': errors.title }" />
          </div>
        </div>
        <div class="error-message"><small id="status_help">{{errors.title}}</small></div>
        <div class="form-group form-group-sm">
          <label class="col-xs-12 col-sm-2 control-label" for="location"> Location <span class="required">*</span> : </label>
          <div class="col-xs-12 col-sm-8">
            <InputText name="location" class="formwidthmedium form-control" v-model="home" :class="{ 'p-invalid': errors.home }" />
          </div>
        </div>
        <div class="error-message"><small id="status_help">{{errors.home}}</small></div>
        <div class="form-group form-group-sm">
          <label class="col-xs-12 col-sm-2 control-label" for="biography">Biography <span class="required">*</span> : </label>
          <div class="col-xs-12 col-sm-8">
            <Textarea name="biorgraphy" v-model="biography" rows="5" cols="120" :autoresize="true" :class="{ 'p-invalid': errors.biography }" />
          </div>
        </div>
        <div class="error-message"><small id="status_help">{{errors.biography}}</small></div>
        <div class="form-group form-group-sm" v-if="imageFilename">
          <label class="col-xs-12 col-sm-2 control-label" for="trainerimage">Image: <span class="required">*</span> : </label>
          <img class="profile-image" name="trainerimage" :src="baseURL + trainerImagesPath + imageFilename">
        </div>

        <div class="form-group form-group-sm">
          <label class="col-xs-12 col-sm-2 control-label" for="trainerimage">Select Photo: <span class="required">*</span> : </label>
          <div class="upload-button"><FileUpload 
            mode="basic"
            name="files[]"
            :auto="true"
            :url="`${baseURL}api/v1/cms/trainer/resource/image/add`" 
            @error="uploadImageError" 
            @upload="uploadImageComplete" 
            :disable="true"
          /></div>
        </div>
        <div class="error-message"><small id="status_help">{{errors.imageFilename}}</small></div>
        <div class="form-group">
          <label>&nbsp;</label>
          <div class="formwidthmedium"><button type="submit" class="btn-orange btn btn-default" @click="submit()">Submit</button></div>
      </div>

      </div>
    </div>
    <cmsFooter />
  </div>
</template>

<script>
// CORE
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { useField, useForm } from 'vee-validate'
import { object, string } from 'yup'

// Schema
// import Trainer from '../../../schemas/Trainer.js'

// Components
import cmsHeader from '../../../components/cms/cmsHeader.vue'
import cmsFooter from '../../../components/cms/cmsFooter.vue'

// API Services
import TrainingServices from '../../../services/TrainerServices.js'

// Primevue
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import FileUpload from 'primevue/fileupload'

export default {
  name: 'cmsTrainerEdit',
  components: {
    cmsHeader,
    cmsFooter,
    InputText,
    Textarea,
    FileUpload
  },
  setup() {
    const router = useRouter()
    const toast = useToast()
    const baseURL = process.env.VUE_APP_API_SERVER
    const environment = process.env.VUE_APP_ENVIRONMENT
    const trainerImagesPath = process.env.VUE_APP_TRAINER_IMAGES
    const currentImageFilename = ref('')

    const createTrainer = (newTrainer) => {
      const trainer = ref(newTrainer)
      TrainingServices.createTrainingResource(trainer.value)
      .then((response) => {
        if(response.status != 200) {
          toast.add({severity: 'info', summary: 'Information', detail: response.data, life: 5000})
        } else {
          toast.add({severity: 'success', summary: 'Success', detail: `Training resource ${trainer.value.name} has been added!`, life: 5000})
          router.push({ name:"cmsTrainerList" })
        }
      })
      .catch(() => {
        toast.add({severity: 'error', summary: 'Error', detail: 'Training resource could not be added!', life: 5000})
      })
    }

    const uploadImageComplete = (data) => {
      const result = JSON.parse(data.xhr.response)
      if(currentImageFilename.value) { TrainingServices.deleteImageFromFileSystem(currentImageFilename.value) }
      imageFilename.value = result.filename[0]
      currentImageFilename.value = imageFilename.value
    }

    const uploadImageError = () => {
      toast.add({severity:'error', summary: 'Upload Error', detail:'Failed to upload Image!', life: 5000})
    }

    // VALIDATION
    const validationSchema = object({
      name: string().required('* required').min(3),
      title: string().required('* required').min(3),
      home: string().required('* required').min(3),
      biography: string().required('* required').min(3),
      imageFilename: string().required('* required').min(3)
    })

    const { handleSubmit, errors } = useForm({
      validationSchema
    })

    const { value: name } = useField('name')
    const { value: title } = useField('title')
    const { value: home } = useField('home')
    const { value: biography } = useField('biography')
    const { value: imageFilename } = useField('imageFilename')

    const submit = handleSubmit(values => {
      const trainer = values
      createTrainer(trainer)
    })

    return {
      toast,
      confirm,
      baseURL,
      environment,
      trainerImagesPath,
      createTrainer,
      uploadImageComplete,
      uploadImageError,
      name,
      title,
      home,
      biography,
      imageFilename,
      errors,
      submit
    }
  }
}
</script>

<style lang="scss" scoped>
.sizes {
  .p-inputtext {
    display: block;
    margin-bottom: .5rem;

    &:last-child {
        margin-bottom: 0;
    }
  }
}
.field {
  display: flex;
  flex-direction: column nowrap;
  justify-content: flex-start;
  background-color: lightgrey;
}
.profile-image {
  display: flex;
  flex-direction: column nowrap;
  max-width: 10%;
  min-width: 10%;
  border-color: Green;
  border-style: solid;
  border-width: 1px;
  border-radius: 75px;
  background-color: green;
}
.upload-button {
  display: flex;
  flex-direction: column nowrap;
  justify-self: start;
}
.error-message {
  display: flex;
  color: red;
  font-variant: small-caps;
  font-size: 1em;
  text-align: left;
  justify-items: left;
  margin-left: 17.5%;
}
</style>
